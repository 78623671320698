<template>
    <CCard>
        <CCardHeader class="text-center bg-dark text-white">
            <b>{{$t('label.planificacionEstiba')}}</b>
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol col="12" xl="12">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        hover
                        sorter
                        small
                        column-filter
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        :items="formatedItems"
                        :fields="fields"
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="tableText.itemsPerPage"
                        pagination
                        :loading="loading"
                         
                    >
                        <template #loading>
                            <loading/>
                        </template>
                        <template #ItineraryId="{ index }">
                            <td class="text-center">
                                {{index+1}}
                            </td>
                        </template>
                        <template #ValueStatus="{item}">
                            <td class="text-center">
                                <CBadge :style="item.StatusColor">
                                    {{ item.ValueStatus }}
                                </CBadge>
                            </td>
                        </template>
                        <template #acciones="{item, index}">
                            <td class="text-center">
                                <CButton
                                    color="watch"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.toView')+' '+$t('label.planning'),
                                        placement: 'top-end'
                                    }"
                                    @click="updateItinerario(item, index)"
                                >
                                    <CIcon name="eye"/>
                                </CButton>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
        </CCardBody>
    </CCard>
</template>

<script>
    import { tableTextTranslatedHelpers } from '@/_helpers/funciones'

    function fields(){
        return [
            { key: 'ItineraryId', label: '#',_style: 'text-align:center;', filter: false },
            { key: 'DatePlanning',label: this.$t('label.itinerary'), _style:'width:33%;', _classes: 'text-center' },
            { key: 'Voyage', label: this.$t('label.voyageNumber'), _style:'width:10%;', _classes: 'text-center' },
            { key: 'TpVesselName', label: this.$t('label.vesselType'), _style:'width:12%;', _classes: 'text-center' },
            { key: 'UltimoUsuario', label: this.$t('label.user'), _style:'width:10%;', _classes: 'text-center' },
            { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;', _classes:"text-center"},
            { key: 'ValueStatus', label: this.$t('label.status'),  _style:'width:17%; text-align:center;', _classes:"text-center" },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'width:1%; min-width:45px;', 
                sorter: false, 
                filter: false
            },
        ];
    }

    //data
    function data() {
        return {}
    }

    function updateItinerario(item){
        this.$store.state.planificacionestiba.ItineraryId = item.ItineraryId;
        this.$store.state.planificacionestiba.planificacionId = (["", null, undefined].includes(item.StowagePlanningId) ? "" : item.StowagePlanningId);
        this.$store.state.planificacionestiba.collapsePlanificacion = true;
    }

    function StatusColor(item){
        switch (item.Status) {
            case 1:
                return 'success';
            case 2:
                return 'danger';
            case 3:
                return 'warning';
            case 4:
                return 'purple';
            case 5:
                return 'secondary';
            case 6:
                return 'info';
            default:
                return 'primary';
        }
    }

    //computed
    function computedItems () {
        return this.$store.getters["planificacionestiba/myDataItinirarytable"];
    }

    function formatedItems() {
        let _lang = this.$i18n.locale=="en";
        return this.computedItems.map(item => {
            return {
                ...item,
                ValueStatus: _lang=='en' ? item.ValueStatusDsEn : item.ValueStatusDs,
            }
        })
    }
    function tableText(){
        return tableTextTranslatedHelpers(this);
    }
    export default {
        name: 'app-itinerario',
        data,
        props: ['loading'],
        methods: {
            updateItinerario,
            StatusColor,
        },
        computed: {
            computedItems,
            formatedItems,
            fields,
            tableText,
        }
    }
</script>

<style lang="scss" scoped>
    .c-main {
        padding:0px;
    }
    .c-main > .container-fluid, .c-main > .container-sm, .c-main > .container-md, .c-main > .container-lg, .c-main > .container-xl, .c-main > .container-xxl {
        padding:0px;
    }
    .badge-purple {
        background-color: transparent;
        color: #6f42c1;
    }   
    .badge-warning {
        /*color: #4f5d73;*/
        background-color: transparent;
        color: #f9b115;
    }
    .badge-danger {
        background-color: transparent;
        color: #e55353;
    }
    .badge-secondary {
        background-color: transparent;
        color: #ced2d8;
    }

    .badge-info {
        background-color: transparent;
        color: #3399ff;
    }

    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
                vertical-align: middle !important;
            }
        }
    }
</style>